import {
  DesktopComputerIcon,
  StarIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import SignUpTeamBookingBanner from "../../signup/SignUpTeamBookingBanner/SignUpTeamBookingBanner";

export default function LoginFirstStageBlue() {
  const features = [
    {
      title: "Get reviews",
      icon: <StarIcon className="w-6 h-6 " />,
    },
    {
      title: "Edit your website",
      icon: <DesktopComputerIcon className="w-6 h-6" />,
    },
    {
      title: "Manage social media",
      icon: <ThumbUpIcon className="w-6 h-6" />,
    },
  ];

  return (
    <div className="flex flex-col text-white w-full">
      <div className="hidden xl:flex xl:flex-col xl:justify-center xl:items-center w-full">
        <div className="flex items-center justify-center">
          <SignUpTeamBookingBanner />
        </div>
        <div className="flex pt-6">
          {features &&
            features.length > 0 &&
            features.map((feature, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center align-middle justify-center"
                >
                  <div
                    className={`flex items-center justify-center rounded-full pr-3 ${
                      index === 0 ? "pl-0" : "pl-3"
                    }`}
                  >
                    {feature.icon}
                  </div>
                  <p className="text-white text-center leading-6 text-sm-normal whitespace-nowrap">
                    {feature.title}
                  </p>
                  {index < features.length - 1 && (
                    <div className="flex ml-4">
                      <div className="border-r h-8"></div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="text-white text-sm-medium pt-2">and much more...</div>
        <div className="text-body-normal pt-12">
          Trusted by over 750 professionals and counting
        </div>
      </div>
    </div>
  );
}
