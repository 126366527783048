import React, { useEffect, useState } from "react";
import VerificationCodeScreen from "../../common/VerificationCodeScreen/VerificationCodeScreen";
import { useRouter } from "next/router";
import LoginFirstStageGray from "../LoginFirstStageGray/LoginFirstStageGray";
import { useAppStore } from "../../../contexts/StoreContextProvider/StoreContextProvider";
import LoginPage from "../LoginPage/LoginPage";
import {
  onSendVerificationCode,
  onResendHandler,
  onVerify,
  handleFormDataChange,
} from "../../AuthWrapper/AuthWrapper";

//login wrapper extends auth wrapper
const LoginWrapper = () => {
  const { dispatch } = useAppStore();
  const [loginPhoneInput, setLoginPhoneInput] = useState("");
  const [phone, setPhone] = useState("");
  const [flow, setFlow] = useState("login");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const router = useRouter();
  const { from_pro_plan_trial = false } = router.query || {};
  const [phoneError, setPhoneError] = useState("");

  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPhone(loginPhoneInput);
  }, [loginPhoneInput]);

  function handleFormDataChangeLogin(e: any, name: string) {
    handleFormDataChange(e, name, dispatch);
  }

  function changePhoneNumber() {
    setFlow("login");
  }

  function onVerifyLogin() {
    onVerify(
      setLoading,
      phone,
      otpCode,
      setAlertMessage,
      dispatch,
      from_pro_plan_trial,
      router
    );
  }

  function onResendLogin() {
    onResendHandler(phone);
  }

  function onSendVerificationCodeLogin() {
    onSendVerificationCode(
      setLoading,
      isPhoneValid,
      isEmailValid,
      phone,
      setFlow,
      dispatch,
      router
    );
  }

  function showSignUp() {
    router.push("/signup");
    return;
  }

  return (
    <div className="flex fixed inset-0 h-full w-full flex-col bg-gray-50 items-center xl:flex-row xl:justify-center xl:h-screen xl:relative overflow-auto">
      {flow !== "verify phone" && (
        <LoginPage stage={"login"}>
          <LoginFirstStageGray
            formData={null}
            handleFormDataChange={handleFormDataChangeLogin}
            firstNameError={firstNameError}
            setFirstNameError={setFirstNameError}
            lastNameError={lastNameError}
            setLastNameError={setLastNameError}
            emailError={emailError}
            setEmailError={setEmailError}
            phoneError={phoneError}
            setPhoneError={setPhoneError}
            companyError={companyError}
            setCompanyError={setCompanyError}
            setIsPhoneValid={setIsPhoneValid}
            setIsEmailValid={setIsEmailValid}
            onSendVerificationCode={onSendVerificationCodeLogin}
            showSignUp={showSignUp}
            stage={"login"}
            loginPhoneInput={loginPhoneInput}
            setLoginPhoneInput={setLoginPhoneInput}
            loading={loading}
          />
        </LoginPage>
      )}

      {flow === "verify phone" && (
        <VerificationCodeScreen
          onVerify={onVerifyLogin}
          onResend={onResendLogin}
          onChange={() => {
            setAlertMessage("");
          }}
          onPhoneNumberChange={changePhoneNumber}
          setCode={setOtpCode}
          alertMessage={alertMessage}
          loading={loading}
        />
      )}
    </div>
  );
};

export default LoginWrapper;
