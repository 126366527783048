import {
  ExternalLinkIcon,
  UploadIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import {
  ArrowRightIcon,
  ChatIcon,
  MailIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export interface OrangeButtonProps {
  /**
   * most helpful are self alignment utility classes - self-end, self-start etc
   */
  alignment?: string;
  text: string;
  size: string;
  onClickFunc?: any;
  width?: string;
  height?: string;
  spacing?: string;
  type?: "button" | "reset" | "submit";
  filled?: boolean;
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  warning?: boolean;
  cancel?: boolean;
  icon?: any;
  iconPlacement?: string;
  id?: string;
  customStyle?: string;
  children?: any;
}

export default function OrangeButton({
  text,
  size,
  onClickFunc,
  filled,
  disabled,
  loading,
  warning,
  cancel,
  icon,
  iconPlacement,
  id,
  customStyle,
  children,
}: OrangeButtonProps) {
  const spinnerSize = size;

  let style;
  let fbPixel;

  if (loading) {
    style = "bg-caro-orange border border-caro-orange";
  } else if (disabled) {
    style =
      "bg-gray-200 text-gray-400 border border-transparent cursor-not-allowed";
  } else {
    style =
      "border border-caro-orange bg-caro-orange text-white hover:bg-caro-orange-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 transition-colors duration-150 ease-in-out";
  }

  let iconStyle = `text-white ${iconPlacement === "leading" ? "mr-2" : "ml-2"}`;

  switch (size) {
    case "xs":
      size = "px-3 py-1.5 text-xs";
      iconStyle = "h-4 w-4 " + iconStyle;
      break;
    case "sm":
      size = "px-3.5 py-2 text-sm";
      iconStyle = "h-4 w-4 " + iconStyle;
      break;
    case "md":
      size = "px-4 py-2 text-sm";
      iconStyle = "h-5 w-5 " + iconStyle;
      break;
    case "lg":
      size = "px-5 py-2 text-base";
      iconStyle = "h-5 w-5 " + iconStyle;
      break;
    case "xl":
      size = "px-6 py-3 text-base";
      iconStyle = "h-5 w-5 " + iconStyle;
      break;
  }

  if (icon) {
    style = "flex " + style;
    switch (icon) {
      case "chat":
        icon = <ChatIcon className={iconStyle} />;
        break;
      case "mail":
        icon = <MailIcon className={iconStyle} />;
        break;
      case "external-link":
        icon = <ExternalLinkIcon className={iconStyle} />;
        break;
      case "plus":
        icon = <PlusIcon className={iconStyle} />;
        break;
      case "delete":
        icon = <TrashIcon className={iconStyle} />;
        break;
      case "upload":
        icon = <UploadIcon className={iconStyle} />;
        break;
      case "export":
        icon = <DownloadIcon className={iconStyle} />;
        break;
      case "right-arrow":
        icon = <ArrowRightIcon className={iconStyle} />;
        break;
    }
  }

  if (text === "Next1") {
    fbPixel = true;
  }

  if (customStyle) {
    style = style + " " + customStyle;
  }

  return (
    <>
      <button
        id={id || ""}
        type="button"
        data-cy={id}
        disabled={disabled}
        className={`${size} ${style} justify-center items-center rounded-full font-medium shadow-sm focus:outline-none`}
        onClick={onClickFunc}
      >
        {iconPlacement === "leading" && icon}
        {loading ? (
          <div className="flex justify-center">
            <LoadingSpinner
              size={
                spinnerSize === "lg" || spinnerSize === "xl"
                  ? "big-spinner"
                  : ""
              }
            />
          </div>
        ) : (
          text
        )}
        {iconPlacement === "lagging" && icon}
        {children}
      </button>
    </>
  );
}
