import { AsYouType } from "libphonenumber-js";
import { useState } from "react";
import OnboardingGrayContainer from "../../checkout/OnboardingGrayContainer/OnboardingGrayContainer";
import DynamicInput from "../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import OrangeButton from "../../uiwrappers/OrangeButton/OrangeButton";
import PrimaryLink from "../../uiwrappers/PrimaryLink/PrimaryLink";
import { useRouter } from "next/router";

interface LoginFirstStageGrayProps {
  formData: any;
  handleFormDataChange: any;
  firstNameError: any;
  setFirstNameError: any;
  lastNameError: any;
  setLastNameError: any;
  emailError: any;
  setEmailError: any;
  phoneError: any;
  setPhoneError: any;
  companyError: any;
  setCompanyError: any;
  setIsPhoneValid: any;
  setIsEmailValid: any;
  onSendVerificationCode: any;
  showSignUp: () => void;
  stage: string;
  loginPhoneInput: string;
  setLoginPhoneInput: (arg0: string) => void;
  loading: boolean;
}

export default function LoginFirstStageGray({
  formData,
  handleFormDataChange,
  firstNameError,
  setFirstNameError,
  lastNameError,
  setLastNameError,
  emailError,
  setEmailError,
  phoneError,
  setPhoneError,
  companyError,
  setCompanyError,
  setIsPhoneValid,
  setIsEmailValid,
  onSendVerificationCode,
  showSignUp,
  stage,
  loginPhoneInput,
  setLoginPhoneInput,
  loading,
}: LoginFirstStageGrayProps) {
  const [isInputValid, setIsInputValid] = useState(true);
  const router = useRouter();
  const { from_pro_plan_trial = false } = router.query || {};

  const handlePhoneNumberChange = (e: any) => {
    const phoneNumber = e["verification-contact"];
    if (/(.?\d){4,}/.test(phoneNumber)) {
      const formattedPhoneNumber = new AsYouType("US").input(phoneNumber);
      setLoginPhoneInput(formattedPhoneNumber);
    } else {
      setLoginPhoneInput(phoneNumber);
    }
  };

  return (
    <div className="flex flex-col justify-center w-full h-full bg-gray-50">
      <OnboardingGrayContainer>
        <div className="flex w-full justify-center">
          <div className="flex flex-col w-500 xl:max-w-md">
            {stage === "login" && (
              <>
                <div className="rounded-md -space-y-px">
                  <div className="">
                    <div className="">
                      <DynamicInput
                        name="verification-contact"
                        value={loginPhoneInput}
                        label="Phone Number"
                        edit={true}
                        handleLocalEdits={(e: any) => {
                          handlePhoneNumberChange(e);
                          setPhoneError("");
                        }}
                        validationOn={true}
                        setIsInputValid={setIsInputValid}
                        onSubmit={onSendVerificationCode}
                        externalErrorMessage={phoneError}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <OrangeButton
              id="send-code-button"
              text={from_pro_plan_trial ? "Try 1 month" : "Get Started"}
              size="md"
              onClickFunc={onSendVerificationCode}
              customStyle="w-full mt-6"
              loading={loading}
              disabled={loading}
            />

            {!from_pro_plan_trial && (
              <div className="text-center pt-1">
                <div className="inline text-sm-normal text-gray-600 text-center">
                  {"Don't have an account?"}
                </div>
                <span
                  onClick={showSignUp}
                  className="inline-flex text-body-medium ml-1 text-blue-900 hover:text-blue-500"
                >
                  <PrimaryLink text={"Sign up"} size="md" />
                </span>
              </div>
            )}
          </div>
        </div>
      </OnboardingGrayContainer>
    </div>
  );
}
