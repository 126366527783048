interface ToplineProLogoProps {
  style: string;
}

export default function ToplineProLogo({ style }: ToplineProLogoProps) {
  return (
    <img
      className={style ? style : "w-auto h-10"}
      src="/topline-pro-white-text.svg"
      alt="Topline Pro Logo"
    />
  );
}
