import { signIn } from "next-auth/react";
import {
  check_existence,
  getFormattedPhoneNumber,
  getNumberFromString,
  sendOtpToUser,
} from "../login/utils";
import { unformatPhoneNumber } from "../primaryrender/contacts/helpers/formatPhoneNumber";
import { setAuth0Id, updateFormData } from "../signup/store/actions";
import { getAuthToken, getUserId } from "../../hooks/getAuthUserId";
import { createStripeCheckoutSession, updateUserInfo } from "../checkout/utils";
import { toast } from "react-toastify";
import validator from "validator";
import { NextRouter } from "next/router";

export async function onSendVerificationCode(
  setLoading: (arg0: boolean) => void,
  isPhoneValid: any,
  isEmailValid: any,
  phone: any,
  setFlow: (arg0: string) => void,
  dispatch: (arg0: { type: string; payload: any }) => void,
  router: any
) {
  if (!isPhoneValid || !isEmailValid || !validator.isMobilePhone(phone)) {
    return;
  }

  const validForm = !!phone;

  if (validForm) {
    setLoading(true);
    const formattedPhoneNumber = getFormattedPhoneNumber(phone);
    if (formattedPhoneNumber) {
      const existingCustomer = await check_existence(
        "phone",
        formattedPhoneNumber
      );

      if (!existingCustomer) {
        setLoading(false);
        const unformattedNumber = unformatPhoneNumber(phone);
        const input = { phoneNumber: unformattedNumber };
        handleFormDataChange(input, "phoneNumber", dispatch);
        router.push("/signup");
        return;
      }

      setFlow("verify phone");
      const currentPhoneNumber = getNumberFromString(phone);
      const newPhoneNumber = getFormattedPhoneNumber(currentPhoneNumber);
      sendOtpToUser(newPhoneNumber);
    }
  }

  setLoading(false);
}

export async function onResendHandler(phone: any) {
  await sendOtpToUser(getFormattedPhoneNumber(phone));
  toast.success("Code has been resent");
}

export async function onVerify(
  setLoading: (arg0: boolean) => void,
  phone: any,
  otpCode: any,
  setAlertMessage: (arg0: string) => void,
  dispatch: (arg0: { type: string; payload: any }) => void,
  from_pro_plan_trial: any,
  router: NextRouter
) {
  setLoading(true);
  const currentPhoneNumber = getNumberFromString(phone);
  const formattedPhoneNumber = getFormattedPhoneNumber(currentPhoneNumber);

  if (formattedPhoneNumber) {
    const signInResponse = await signIn("credentials", {
      redirect: false,
      phoneNumber: formattedPhoneNumber,
      code: otpCode,
      channel: "sms",
    });
    if (signInResponse?.error) {
      setAlertMessage(
        "Your verification code did not match. Please try again."
      );
      setLoading(false);
      return;
    }

    const authToken = await getAuthToken();
    const userId = await getUserId();
    if (!authToken) {
      router.push("/signout");
      return;
    }

    dispatch(setAuth0Id(userId));

    if (from_pro_plan_trial) {
      router.push("/_functional/initiate-pro-plan-trial");
      return;
    }

    router.push(router?.query?.redirect || "/home");
  }

  setTimeout(() => {
    setLoading(false);
  }, 2000);
}

export async function redirectToStripe(
  userId: string,
  subscriptionPlanId: string,
  landingPageId: string,
  router: NextRouter
) {
  const confirmationInfo = await createStripeCheckoutSession(
    subscriptionPlanId,
    landingPageId
  );

  if (confirmationInfo?.url) {
    const input = {
      landing_page_id: landingPageId,
    };
    await updateUserInfo(userId, input);
    router.push(confirmationInfo?.url);
    return;
  }
}

export function handleFormDataChange(
  e: any,
  name: string,
  dispatch: (arg0: { type: string; payload: any }) => void
) {
  let value = e[name];
  // remove white space for email and phone number inputs
  if (name === "email" || name === "phoneNumber")
    value = value.replace(/\s/g, "");

  dispatch(updateFormData(name, value));
}
