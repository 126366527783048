import React, { useEffect, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import { ChatIcon } from "@heroicons/react/solid";
import PrimaryButton from "../../uiwrappers/PrimaryButton/PrimaryButton";
import PrimaryLink from "../../uiwrappers/PrimaryLink/PrimaryLink";
import GenericContainer from "../GenericContainer/GenericContainer";
import Alert from "../../uiwrappers/Alert/Alert";
import ToplineProLogo from "../ToplineProWhiteText/ToplineProWhiteText";
import OrangeButton from "../../uiwrappers/OrangeButton/OrangeButton";
import useIsMobile from "../../../hooks/useIsMobile";
import axios from "axios";

interface VerificationCodeScreenProps {
  setCode: React.Dispatch<React.SetStateAction<string>>;
  onVerify: () => void;
  onResend: () => void;
  onPhoneNumberChange: () => void;
  onChange: (code: string) => void;
  alertMessage?: any;
  loading: boolean;
}

const VerificationCodeScreen = ({
  onVerify,
  onResend,
  onChange,
  onPhoneNumberChange,
  setCode,
  alertMessage,
  loading,
}: VerificationCodeScreenProps) => {
  const isMobile = useIsMobile();
  const [oneTimeCode, setOneTimeCode] = useState("");

  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || "";
  const showCode = ["development", "staging"].includes(environment);

  async function fetchCode() {
    const response = await axios.get("../api/twilio/fetch_otp_code");
    const code = response.data;
    setOneTimeCode(code);
  }

  useEffect(() => {
    if (showCode) {
      setTimeout(() => {
        fetchCode();
      }, 3000);
    }
  }, []);

  function handleVerifyClick() {
    onVerify();
  }

  const onChangeHandler = (inputValue: any) => {
    const inputCode = inputValue;
    setCode(inputCode);
    onChange(inputCode);
  };

  return isMobile ? (
    <div className="flex flex-col xl:flex-row z-10 fixed h-screen w-full bg-gray-50 items-center justify-center">
      <div className="w-full p-6 xl:p-12 bg-blue-900 xl:bg-gray-50">
        <ToplineProLogo style="xl:hidden w-auto h-10" />

        <h1 className="pt-6 text-h1-bold text-white xl:text-gray-900">
          Verify your phone number
        </h1>
        <h2 className="pt-2 text-h2-medium text-white xl:text-gray-900">
          Enter the 6-digit code we texted you
        </h2>

        {showCode && (
          <div className="text-body-normal text-center my-4 text-white">
            Type in this code: {oneTimeCode}
          </div>
        )}
      </div>

      <div className="flex flex-col items-center h-full p-6">
        <div className="rounded-md -space-y-px">
          <div className="w-full">
            <div className="custom-styles">
              <div>
                <ReactInputVerificationCode
                  length={6}
                  autoFocus={true}
                  onChange={onChangeHandler}
                  placeholder={""}
                  dataCy={"one-time-code"}
                />
                {alertMessage && (
                  <Alert alertMessage={alertMessage} customStyle="mt-3" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col items-center w-full text-center text-sm-normal text-gray-600 my-6">
            <div>Didn’t receive a code?</div>
            <p className="inline-flex">
              <PrimaryLink
                text="Resend "
                size="md"
                onClickFunc={onResend}
                id="resend-code"
                customStyle="mr-1"
              />
              <span className="mr-1">or</span>
              <PrimaryLink
                text="Change phone number"
                size="md"
                onClickFunc={onPhoneNumberChange}
              />
            </p>
          </div>
          <OrangeButton
            id="confirm-code-button"
            text="Confirm Code"
            size="md"
            disabled={loading}
            loading={loading}
            onClickFunc={handleVerifyClick}
            customStyle="w-full"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <GenericContainer customStyle="shadow-md items-center justify-center lg:-mt-6 max-w-2xl w-full space-y-8 py-12 px-24">
        {showCode && (
          <div className="text-body-normal text-center text-gray-900">
            Type in this code: {oneTimeCode}
          </div>
        )}
        <div className="flex flex-col items-center">
          <img
            className="w-auto absolute top-12 h-8 lg:left-12"
            src="/Topline-logo-expanded.svg"
            alt="Topline Pro Logo"
          />
          <h2 className="text-center text-display-bold text-gray-900">
            Verify your account
          </h2>
          <div className="flex items-center  mt-3 md:mt-4 ">
            <ChatIcon
              className="h-6 w-6 text-gray-600 mr-2 flex-shrink-0 hidden lg:block"
              aria-hidden="true"
            />
            <p className="text-sm-normal text-gray-600 mb-0 false">
              Enter the 6-digit code that we&apos;ve texted you.
            </p>
          </div>
        </div>
        <input type="hidden" name="remember" value="true" />
        <div className="false false  false rounded-md -space-y-px">
          <div className="w-full">
            <div className="custom-styles">
              <div>
                <ReactInputVerificationCode
                  length={6}
                  autoFocus={true}
                  onChange={onChangeHandler}
                  placeholder={""}
                  dataCy={"one-time-code"}
                />
                {alertMessage && (
                  <Alert alertMessage={alertMessage} customStyle="mt-3" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="mt-2 text-center text-sm-normal text-gray-600 xl:flex xl:justify-center">
            Didn’t receive a code?
            <p className="inline-flex sm:ml-1 mb-6 sm:mb-4">
              <PrimaryLink
                text="Resend "
                size="md"
                onClickFunc={onResend}
                id="resend-code"
                customStyle="mr-1"
              />
              <span className="mr-1">or</span>
              <PrimaryLink
                text="Change phone number"
                size="md"
                onClickFunc={onPhoneNumberChange}
              />
            </p>
          </p>
          <PrimaryButton
            id="confirm-code-button"
            text="Confirm Code"
            size="xl"
            disabled={loading}
            loading={loading}
            onClickFunc={handleVerifyClick}
            customStyle="w-full"
          />
        </div>
      </GenericContainer>
    </div>
  );
};

export default VerificationCodeScreen;
