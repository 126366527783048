import { CONSTANTS } from "../constants";

export const setFormValidationStatus = (status: boolean) => ({
  type: CONSTANTS.SET_FORM_VALIDATION_STATUS,
  payload: status,
});

export const setIsSubmitButtonDisabled = (status: boolean) => ({
  type: CONSTANTS.SET_IS_SUBMIT_BUTTON_DISABLED,
  payload: status,
});

export const setIsSignedUp = (status: boolean) => ({
  type: CONSTANTS.SET_IS_SIGNED_UP,
  payload: status,
});

export const setAuth0Id = (auth0Id: string) => ({
  type: CONSTANTS.SET_AUTH0_ID,
  payload: auth0Id,
});

export const updateFormData = (name: string, value: string | string[]) => ({
  type: CONSTANTS.UPDATE_SIGNUP_FORM_DATA,
  payload: {
    name,
    value,
  },
});
