import ToplineProLogo from "../../common/ToplineProWhiteText/ToplineProWhiteText";
import LoginFirstStageBlue from "../LoginFirstStageBlue/LoginFirstStageBlue";
import { useRouter } from "next/router";

interface LoginPageProps {
  children: any;
  stage: any;
}

export default function LoginPage({ children, stage }: LoginPageProps) {
  const router = useRouter();
  const { from_pro_plan_trial = false } = router.query || {};
  stage = from_pro_plan_trial ? "pro_plan_trial" : stage;
  return (
    <>
      <div className="flex flex-col xl:w-2/5 xl:h-full bg-blue-900 w-full">
        <div className="xl:flex xl:flex-col items-start hidden bg-blue-900 xl:p-16 xl:pb-0 p-6">
          <ToplineProLogo style="flex items-start justify-start w-auto h-10" />
          {(stage === "login" || stage === "signup") && (
            <>
              <h1 className="pt-4 xl:pt-6 text-h1-bold text-white">
                Welcome to Topline Pro
              </h1>
              <h2 className="pt-2 text-h2-medium text-white">
                Let's grow your company together
              </h2>
            </>
          )}

          {stage === "pro_plan_trial" && (
            <>
              <h1 className="pt-4 xl:pt-6 text-h1-bold text-white">
                Get started with your free upgrade
              </h1>
              <h2 className="pt-2 text-h2-medium text-white">
                Try 1 month of our Pro Plan at no additional cost
              </h2>
            </>
          )}
        </div>

        <div
          className={`flex flex-col xl:h-full items-start bg-blue-900 xl:px-16 xl:pt-8 p-6 ${
            stage === "signup" || stage === "login"
              ? "xl:justify-center"
              : "xl:justify-center"
          }`}
        >
          {(stage === "login" || stage === "signup") && (
            <ToplineProLogo style="xl:hidden flex items-start justify-start w-auto h-10" />
          )}
          <div className="xl:hidden">
            {(stage === "login" || stage === "signup") && (
              <>
                <h1 className="pt-4 xl:pt-6 text-h1-bold text-white">
                  Welcome to Topline Pro
                </h1>
                <h2 className="pt-2 text-h2-medium text-white">
                  Let's grow your company together
                </h2>
              </>
            )}
            {stage === "pro_plan_trial" && (
              <>
                <h1 className="pt-4 xl:pt-6 text-h1-bold text-white">
                  Get started with your free upgrade
                </h1>
                <h2 className="pt-2 text-h2-medium text-white">
                  Try 1 month of our Pro Plan at no additional cost
                </h2>
              </>
            )}
          </div>
          {(stage === "signup" || stage === "login") && <LoginFirstStageBlue />}
        </div>

        {(stage === "signup" || stage === "login") && (
          <div className="hidden xl:flex xl:h-80 w-full"></div>
        )}
      </div>
      <div className="flex xl:w-3/5 xl:h-full xl:items-center justify-center bg-gray-50 w-full">
        {children}
      </div>
    </>
  );
}
