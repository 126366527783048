import React from "react";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { getSession } from "next-auth/react";
import LoginWrapper from "../components/login/LoginWrapper/LoginWrapper";
import { URL_CONSTANTS } from "../constants/urlConstants";

const Login = () => {
  return (
    <>
      <LoginWrapper />
    </>
  );
};
export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const session = await getSession(context);

  if (session) {
    return {
      redirect: {
        destination: URL_CONSTANTS.PRIMARY_INDEX_ROUTE,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};
export default Login;
